import { Box, Grid, Typography } from "@mui/material";

interface SystemSizeProps {
  sitePowerCapacity: string | null;
  siteEnergyCapacity: string | null;
  solarOutput: number | null;
  batteryOutput: number | null;
  gensetOutput: number | null;
  batteryStorage: number | null;
}
const formatValueGeneral = (value: string | number | null) =>
  value !== null ? value.toLocaleString(): "-";

const formatValue = (value: string | number | null) =>
  value !== null ? value.toLocaleString() + " kW": "-";

const formatValueEnergy = (value: string | number | null) =>
  value !== null ? value.toLocaleString() + " kWh": "-";

const SystemSizeCard: React.FC<SystemSizeProps> = ({
  sitePowerCapacity,
  siteEnergyCapacity,
  solarOutput,
  batteryOutput,
  gensetOutput,
  batteryStorage,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "1.5rem",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={4} mt="-2rem">
          <Typography variant="subtitle2">
            System Size
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} container spacing={3.5} justifyContent="flex-end" textAlign="center">
          <Grid item xs="auto" textAlign="left">
            <Typography variant="body2" color="text.secondary">
              Site Output Capacity
            </Typography>
            <Typography variant="h6" color="text.primary">
              {formatValueGeneral(sitePowerCapacity)}
            </Typography>
          </Grid>
          <Grid item xs="auto" textAlign="left">
            <Typography variant="body2" color="text.secondary">
              Site Storage Capacity
            </Typography>
            <Typography variant="h6" color="text.primary">
              {formatValueGeneral(siteEnergyCapacity)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={2} textAlign="center">
        <Grid item xs={3.5} sm={3.5} />

        <Grid item xs={3} sm={2} textAlign="left">
          <Typography variant="body2" color="text.secondary">
            Solar Output
          </Typography>
          <Typography variant="body1" color="text.primary">
            {formatValue(solarOutput)}
          </Typography>
        </Grid>

        <Grid item xs={3} sm={2} textAlign="left">
          <Typography variant="body2" color="text.secondary">
            Battery Output
          </Typography>
          <Typography variant="body1" color="text.primary">
            {formatValue(batteryOutput)}
          </Typography>
        </Grid>

        <Grid item xs={3} sm={2} textAlign="left">
          <Typography variant="body2" color="text.secondary">
            Genset Output
          </Typography>
          <Typography variant="body1" color="text.primary">
            {formatValue(gensetOutput)}
          </Typography>
        </Grid>

        <Grid item xs={3} sm={2} textAlign="left">
          <Typography variant="body2" color="text.secondary">
            Battery Storage
          </Typography>
          <Typography variant="body1" color="text.primary">
            {formatValueEnergy(batteryStorage)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SystemSizeCard;
