/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
export const siteData = {
  statusList: ["Pre-Commissioning", "Commissioning", "Operating", "Retired"],
  contractTypesList: ["MSA", "AMA", "PPA", "VDER", "NEM", "ESA", "CREF"],
  dealStructuresList: ["Financed", "Capital Sale"],
  billSavingsProvidersList: ["Schneider", "CPower", "Energy Toolbase", "NONE"],
  gridServicesProvidersList: ["CPower", "Energy Toolbase", "NONE"],
};

export const assetsTypeList = ["Solar", "Inverter", "BESS", "Genset", "Controller"];

export const solarMountingTypes = ["Carport", "Ground-Mounted", "Rooftop"];

export const gensetTypes = ["CHP", "Dispatchable", "Prime Power"];
