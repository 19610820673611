/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormGroup,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useMutation } from "@tanstack/react-query";
import {
  getUtilityListQuery,
  getIsoListQuery,
  getGasUtilitiesListQuery,
  getSiteSettingsMutation,
  deleteOrganizationMutation,
  postSiteSettingsMutation,
  getSiteMetaDataAssembliesQuery,
  getManufacturersListQuery,
} from "../../lib/Queries";
import Loading from "../Shared/Loading";
import countries from "../../lib/Countries";
import { siteData, assetsTypeList } from "../../lib/siteData";
import DataComponentError from "../Shared/DataComponentError";
import { LoadingButton } from "@mui/lab";
import timezones from "../../lib/Timezones";
import sgipOptionsList from "../../lib/sgipCategories";
import states from "../../lib/States";
import PropTypes from "prop-types";
import DeleteSiteModal from "./SiteDeleteModal";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import { getPowerOutput, getEnergyOutput } from "../Site/SiteSpecs";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SystemSizeCard from "./SystemSizeCard";
import AssetSetupModal from "./assetModal";
import AssetPreFillModal from "./preFillAssetsModal";
import PlaylistPlayOutlined from "@mui/icons-material/PlaylistPlayOutlined";
import { useFlags } from "launchdarkly-react-client-sdk";

const styles = {
  caption: {
    color: "rgba(0, 0, 0, 0.60)",
    marginBottom: "1rem",
  },
  settingsPanel: {
    width: "100%",
    py: "3rem",
    px: "4rem",
  },
  settingsNewPanel: {
    width: "100%",
    py: "3rem",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700",
  },
  genericTab: {
    backgroundColor: "#fff",
    border: "1px solid #E1E2E4",
    borderRadius: "5px",
    padding: "2rem",
    width: "184px",
    height: "151px",
    justifyContent: "center",
    fontSize: 12,
  },
  genericTabLabel: {
    fontSize: 14,
  },
};

const SiteSettings = ({ OrganizationUuid, site, onClose }) => {
  const {
    statusList,
    contractTypesList,
    dealStructuresList,
    billSavingsProvidersList,
    gridServicesProvidersList,
  } = siteData;
  const theme = useTheme();
  const [currentSite, setSite] = useState(site);
  const [dirty, setDirty] = useState(false);
  const [name, setName] = useState(currentSite?.name || null);
  const [siteState, setSiteState] = useState(currentSite?.state || null);
  const [iso, setIso] = useState(currentSite?.iso || null);
  const [gasUtility, setGasUtility] = useState(currentSite?.gas_utility || null);
  const [utility, setUtility] = useState(currentSite?.utility || null);
  const [timezone, setTimezone] = useState(currentSite?.timezone || null);
  const [address1, setAddress1] = useState(currentSite?.address?.address1 || null);
  const [city, setCity] = useState(currentSite?.address?.city || null);
  const [zip, setZip] = useState(currentSite?.address?.zip || null);
  const [usState, setUsState] = useState(currentSite?.address?.state || null);
  const [country, setCountry] = useState(currentSite?.address?.country || "United States");
  const [latitude, setLatitude] = useState(currentSite?.latitude || null);
  const [longitude, setLongitude] = useState(currentSite?.longitude || null);
  const [siteNotifications, setSiteNotifications] = useState(
    currentSite?.send_notifications || currentSite?.uuid === undefined,
  );
  const [contractType, setContractType] = useState(currentSite?.contract_type || []);
  const [dealStructure, setDealStructure] = useState(currentSite?.deal_structure || []);
  const [billSavingsProvider, setBillSavingsProvider] = useState(
    currentSite?.bill_savings_provider || null,
  );
  const [gridServicesProvider, setGridServicesProvider] = useState(
    currentSite?.grid_services_provider || null,
  );
  const [commissionedDate, setCommissionedDate] = useState(
    currentSite?.commissioned_date ? new dayjs(currentSite.commissioned_date) : null,
  );
  const [contractEndDate, setContractEndDate] = useState(
    currentSite?.contract_end_date ? new dayjs(currentSite.contract_end_date) : null,
  );
  const [dateAcquired, setDateAcquired] = useState(
    currentSite?.date_acquired ? new dayjs(currentSite.date_acquired) : null,
  );
  const [sgipBudgetCategory, setSgipBudgetCategory] = useState(
    currentSite?.sgip_budget_category || null,
  );
  const [sgipStartDate, setSgipStartDate] = useState(
    currentSite?.sgip_start_date ? new dayjs(currentSite.sgip_start_date) : null,
  );
  const [solarCapacity, setSolarCapacity] = useState(currentSite?.solar_capacity || null);
  const [batteryCapacity, setBatteryCapacity] = useState(currentSite?.battery_capacity || null);
  const [batteryEnergyCapacity, setBatteryEnergyCapacity] = useState(
    currentSite?.battery_energy_capacity || null,
  );
  const [gensetCapacity, setGensetCapacity] = useState(currentSite?.genset_capacity || null);
  const [edgeId, setEdgeId] = useState(currentSite?.edge_id || null);
  const [open, setOpen] = useState(false); // State to control modal visibility
  const [isPreFilledModalOpen, setIsPreFilledModalOpen] = useState(false);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false); // asset Modal
  const [selectedAsset, setSelectedAsset] = useState(null);
  const isLoading = false;
  const error = false;
  const scaleosType = "All";
  const { siteSpec4 } = useFlags();
  const {
    data: isoList,
    isLoading: isoListIsLoading,
    refetch: refetchIsoList,
  } = useQuery({
    ...getIsoListQuery(),
  });
  const {
    data: gasUtilitiesList,
    isLoading: gasUtilitiesListIsLoading,
    refetch: refetchGasUtilitiesList,
  } = useQuery({
    ...getGasUtilitiesListQuery(),
  });
  const {
    data: utilityList,
    isLoading: utilityListIsLoading,
    refetch: refetchUtilityList,
  } = useQuery({
    ...getUtilityListQuery(false),
  });

  const {
    data: assembliesList,
    isLoading: isAssembliesLoading,
    refetch: refetchAssembliesList,
  } = useQuery(currentSite?.uuid ? getSiteMetaDataAssembliesQuery(currentSite.uuid) : () => null, {
    enabled: !!currentSite?.uuid,
  });

  const {
    data: manufacturersList,
    isLoading: manufacturersListIsLoading,
    refetch: refetchManufacturersList,
  } = useQuery({
    ...getManufacturersListQuery(scaleosType),
  });

  const mutation = useMutation({
    ...getSiteSettingsMutation(),
  });

  const mutation_delete = useMutation({
    ...deleteOrganizationMutation(),
  });

  const mutation_new_site = useMutation({
    ...postSiteSettingsMutation(),
  });

  const handleSaveAndClose = () => {
    const newSettings = {
      uuid: currentSite?.uuid,
      name: name,
      state: siteState,
      latitude: latitude,
      longitude: longitude,
      timezone: timezone,
      iso: iso,
      gas_utility: gasUtility,
      Utility: utility,
      send_notifications: siteNotifications,
      organization_uuid: OrganizationUuid,
      address: {
        address1: address1,
        city: city,
        state: usState,
        zip: zip,
        country: country,
      },
      contract_type: contractType,
      deal_structure: dealStructure,
      bill_savings_provider: billSavingsProvider,
      grid_services_provider: gridServicesProvider,
      commissioned_date: commissionedDate,
      contract_end_date: contractEndDate,
      date_acquired: dateAcquired,
      sgip_budget_category: sgipBudgetCategory,
      sgip_start_date: sgipStartDate,
      solar_capacity: Number(solarCapacity) || null,
      battery_capacity: Number(batteryCapacity) || null,
      battery_energy_capacity: Number(batteryEnergyCapacity) || null,
      genset_capacity: Number(gensetCapacity) || null,
      group_id: edgeId,
    };

    if (currentSite?.uuid) {
      mutation.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          setSite(newSettings);
        },
      });
    } else {
      mutation_new_site.mutate(newSettings, {
        onSuccess: () => {
          setDirty(false);
          onClose();
        },
      });
    }
  };

  const allFieldsFilled = () => {
    return name && address1 && city && siteState && usState && zip && timezone && utility && iso;
  };

  const timezonesOptions = timezones.map((zone) => ({
    label: zone,
    value: zone,
  }));

  const sgipOptions = sgipOptionsList.map((sgip) => ({
    label: sgip,
    value: sgip,
  }));

  const statesOptions = Object.keys(states).map((stateAbbreviation) => ({
    label: stateAbbreviation,
    value: stateAbbreviation,
  }));

  const getCountries = () => {
    return countries.map((country) => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ));
  };

  const getStatus = () => {
    return statusList.map((status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    ));
  };
  const getContractTypes = () => {
    return contractTypesList.map((contract) => (
      <MenuItem key={contract} value={contract}>
        {contract}
      </MenuItem>
    ));
  };
  const getDealStructures = () => {
    return dealStructuresList.map((deal) => (
      <MenuItem key={deal} value={deal}>
        {deal}
      </MenuItem>
    ));
  };
  const getbillSavingsProviders = () => {
    return billSavingsProvidersList.map((provider) => (
      <MenuItem key={provider} value={provider}>
        {provider}
      </MenuItem>
    ));
  };
  const getServicesProviders = () => {
    return gridServicesProvidersList.map((provider) => (
      <MenuItem key={provider} value={provider}>
        {provider}
      </MenuItem>
    ));
  };

  const handleDelete = () => {
    setOpen(true); // Open the confirmation modal
  };

  const confirmDelete = () => {
    mutation_delete.mutate(currentSite?.uuid, {
      onSuccess: () => {
        console.log("Site deleted successfully");
        setOpen(false); // Close the modal on success
      },
      onError: (error) => {
        console.error("Error deleting site:", error);
        setOpen(false); // Close the modal on error
      },
    });
  };

  const assetsList =
    !isAssembliesLoading && !manufacturersListIsLoading && Array.isArray(assembliesList)
      ? assembliesList
          .filter(
            (assembly) =>
              assembly?.uuid && (assembly.scaleos_type == null || assembly.power_capacity == null),
          )
          .map((assembly) => ({
            id: assembly.uuid,
            name: assembly.name || "Unknown",
            source: assembly.source_info?.[0]?.source || "N/A",
            lastUpdate:
              assembly.last_update && dayjs(assembly.last_update).isValid()
                ? dayjs(assembly.last_update).format("MMM D YYYY, h:mm A")
                : assembly.last_update,
            scaleosName: assembly.scaleos_name,
            manufacturer: assembly.manufacturer,
            manufacturerName:
              manufacturersList.find((m) => m.uuid === assembly.manufacturer)?.name || null,
            model: assembly.model,
            modelName:
              manufacturersList
                .find((m) => m.uuid === assembly.manufacturer)
                ?.model.find((model) => model.uuid === assembly.model)?.name || null,
            outputCapacity: assembly.power_capacity,
            storageCapacity: assembly.energy_capacity,
            sgipCapacity: assembly.sgip_capacity,
            scaleosType: assembly.scaleos_type,
            serialNumber: assembly.serial_number,
            solarMounting: assembly.mounting,
            showInScale: assembly.weight,
            enableAlarms: assembly.trigger_alarms,
            gensetUse: assembly.genset_use,
            sourceInfo: assembly.source_info,
          }))
      : [];
  const rows = assetsList;
  const filteredRows =
    isAssembliesLoading || manufacturersListIsLoading
      ? {}
      : Object.fromEntries(
          assetsTypeList.map((type) => [
            type,
            Array.isArray(assembliesList)
              ? assembliesList
                  .filter(
                    (assembly) =>
                      assembly?.uuid &&
                      assembly.scaleos_type === type &&
                      assembly.power_capacity !== null,
                  )
                  .map((assembly) => ({
                    id: assembly.uuid,
                    name: assembly.name || "Unknown",
                    source: assembly.source_info?.[0]?.source || "N/A",
                    lastUpdate:
                      assembly.last_update && dayjs(assembly.last_update).isValid()
                        ? dayjs(assembly.last_update).format("MMM D YYYY, h:mm A")
                        : assembly.last_update,
                    scaleosName: assembly.scaleos_name,
                    manufacturer: assembly.manufacturer,
                    manufacturerName:
                      manufacturersList.find((m) => m.uuid === assembly.manufacturer)?.name || null,
                    model: assembly.model,
                    modelName:
                      manufacturersList
                        .find((m) => m.uuid === assembly.manufacturer)
                        ?.model.find((model) => model.uuid === assembly.model)?.name || null,
                    outputCapacity: assembly.power_capacity,
                    storageCapacity: assembly.energy_capacity,
                    sgipCapacity: assembly.sgip_capacity,
                    scaleosType: assembly.scaleos_type,
                    serialNumber: assembly.serial_number,
                    solarMounting: assembly.mounting,
                    showInScale: assembly.weight,
                    enableAlarms: assembly.trigger_alarms,
                    gensetUse: assembly.genset_use,
                    sourceInfo: assembly.source_info,
                  }))
              : [],
          ]),
        );

  const columns = [
    { field: "name", headerName: "Asset Name", flex: 1 },
    { field: "source", headerName: "Source", flex: 1 },
    {
      field: "lastUpdate",
      headerName: "Available Since",
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      flex: 0.1,
      align: "right",
      sortable: false,
      renderCell: (params) => <ChevronRightIcon />,
    },
  ];

  const columnsReadyAssets = [
    { field: "scaleosName", headerName: "Scale Name", flex: 1 },
    { field: "manufacturerName", headerName: "Mfr.", flex: 1 },
    { field: "modelName", headerName: "Model", flex: 1 },
    { field: "outputCapacity", headerName: "Capacity (kW)", flex: 1 },
    { field: "source", headerName: "Source", flex: 0.75 },
    {
      field: "action",
      headerName: "",
      flex: 0.1,
      align: "right",
      sortable: false,
      renderCell: (params) => <ChevronRightIcon />,
    },
  ];

  const handleRowClick = (params) => {
    setSelectedAsset(params.row);
    setIsAssetModalOpen(true);
  };

  return (
    <Box>
      <Box sx={currentSite?.uuid && theme.classes.genericPanel} mb="1.5rem">
        {currentSite?.uuid ? (
          <Typography variant="subtitle2" sx={styles.subtitle}>
            Site Details
          </Typography>
        ) : (
          <Typography
            sx={{
              mb: "1.5rem",
              mt: "-1.5rem",
              fontSize: "34px",
              fontWeight: 850,
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            New Site
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="column"
          sx={currentSite?.uuid ? styles.settingsPanel : { ...styles.settingsNewPanel }}
        >
          <Typography variant="h6" mb="1.5rem">
            Info
          </Typography>
          <FormGroup fullWidth variant="outlined" margin="normal">
            <Box mb="24px">
              <TextField
                id="name"
                name="name"
                label="Site Name"
                size="small"
                value={name}
                disabled={!!currentSite?.uuid}
                variant="outlined"
                onChange={(ev) => {
                  setName(ev.target.value);
                  setDirty(true);
                }}
                sx={{ width: "24.5rem", fontSize: 16, fontWeight: 400 }}
                InputProps={{
                  sx: { fontSize: 16, fontWeight: 400 },
                }}
              />
            </Box>
            <Box>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <TextField
                  id="address"
                  name="address"
                  label="Site Address"
                  required
                  value={address1}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setAddress1(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "24.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                  }}
                />
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  required
                  value={city}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setCity(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                  }}
                />
                <FormControl sx={{ width: "5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="usState"
                    name="usState"
                    options={statesOptions}
                    size="small"
                    getOptionLabel={(option) => option.label}
                    value={statesOptions.find((option) => option.value === usState) || null}
                    onChange={(event, newValue) => {
                      setUsState(newValue ? newValue.value : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" required variant="outlined" />
                    )}
                    key={(option) => option.value}
                    disableClearable
                  />
                </FormControl>
                <TextField
                  id="zip"
                  name="zip"
                  label="ZIP"
                  required
                  value={zip}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setZip(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "5rem",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                  }}
                />
              </Box>

              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="country" required>
                    Country
                  </InputLabel>
                  <Select
                    id="country"
                    name="country"
                    label="Country"
                    disabled
                    required
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={country}
                    variant="outlined"
                    onChange={(ev) => {
                      setCountry(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getCountries()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="timezone"
                    name="timezone"
                    options={timezonesOptions}
                    getOptionLabel={(option) => option.label}
                    value={timezonesOptions.find((option) => option.value === timezone) || null}
                    onChange={(event, newValue) => {
                      setTimezone(newValue ? newValue.value : "");
                      setDirty(true);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Time Zone" required variant="outlined" />
                    )}
                    key={(option) => option.value}
                  />
                </FormControl>
                <TextField
                  id="latitude"
                  name="latitude"
                  label="Latitude"
                  value={latitude}
                  size="small"
                  variant="outlined"
                  onChange={(ev) => {
                    setLatitude(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(latitude) || latitude < -90 || latitude > 90) && latitude?.length > 0
                  }
                  helperText={
                    (isNaN(latitude) || latitude < -90 || latitude > 90) && latitude.length > 0
                      ? "Latitude must be a number between -90 and 90"
                      : ""
                  }
                />
                <TextField
                  id="longitude"
                  name="longitude"
                  size="small"
                  label="Longitude"
                  value={longitude}
                  variant="outlined"
                  onChange={(ev) => {
                    setLongitude(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(longitude) || longitude < -180 || longitude > 180) &&
                    longitude?.length > 0
                  }
                  helperText={
                    (isNaN(longitude) || longitude < -180 || longitude > 180) &&
                    longitude.length > 0
                      ? "Longitude must be a number between -180 and 180"
                      : ""
                  }
                />
              </Box>
              <Box mb="2.5rem">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="siteState" required>
                    Status
                  </InputLabel>
                  <Select
                    id="siteState"
                    name="siteState"
                    label="Status"
                    size="small"
                    required
                    value={siteState}
                    variant="outlined"
                    onChange={(ev) => {
                      setSiteState(ev.target.value);
                      setDirty(true);
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                  >
                    {getStatus()}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="large"
                      checked={siteNotifications}
                      onChange={(ev, checked) => {
                        setSiteNotifications(checked);
                        setDirty(true);
                      }}
                      disabled={!currentSite?.uuid}
                    />
                  }
                  label="Enable Site Notifications for Scale Users"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                />
              </Box>
              <Typography variant="h6" mb="1.5rem">
                Connections
              </Typography>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="utility"
                    options={utilityList?.utilities || []}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    value={utilityList?.utilities.find((item) => item.uuid === utility) || null}
                    onChange={(event, newValue) => {
                      setUtility(newValue ? newValue.uuid : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Electric Utility" variant="outlined" required />
                    )}
                    key={(option) => option.uuid}
                  />
                </FormControl>
                <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="gasUtility"
                    options={gasUtilitiesList || []}
                    loading={gasUtilitiesListIsLoading}
                    getOptionLabel={(option) => option.Company}
                    value={gasUtilitiesList?.find((item) => item.UUID === gasUtility) || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setGasUtility(newValue ? newValue.UUID : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Gas Utility" variant="outlined" />
                    )}
                    key={(option) => option.UUID}
                  />
                </FormControl>
              </Box>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="iso"
                    options={isoList?.iso || []}
                    getOptionLabel={(option) => option.name}
                    value={isoList?.iso.find((item) => item.uuid === iso) || null}
                    size="small"
                    onChange={(event, newValue) => {
                      setIso(newValue ? newValue.uuid : "");
                      setDirty(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="ISO / RTO" variant="outlined" required />
                    )}
                    key={(option) => option.uuid}
                  />
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Commercial Operat. Date"
                      value={commissionedDate}
                      onChange={(newValue) => {
                        setCommissionedDate(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box display="flex" mb="3rem" alignItems="center">
                <FormControl sx={{ width: "24.5rem", marginRight: "1.5rem" }}>
                  <Autocomplete
                    id="sgip"
                    name="sgip"
                    options={sgipOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                      sgipOptions.find((option) => option.value === sgipBudgetCategory) || null
                    }
                    onChange={(event, newValue) => {
                      setSgipBudgetCategory(newValue ? newValue.value : "");
                      setDirty(true);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="SGIP Budget Category" variant="outlined" />
                    )}
                    key={(option) => option.value}
                  />
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="SGIP PBI Start Date"
                      value={sgipStartDate}
                      onChange={(newValue) => {
                        setSgipStartDate(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Typography variant="h6" mb="1.5rem">
                Agreements
              </Typography>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Contract Type">Contract Type</InputLabel>
                  <Select
                    id="contractType"
                    name="contractType"
                    multiple
                    label="Contract Type"
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={contractType}
                    variant="outlined"
                    onChange={(ev) => {
                      setContractType(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getContractTypes()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Bill Savings Provider">Bill Savings Provider</InputLabel>
                  <Select
                    id="billSavingsProvider"
                    name="billSavingsProvider"
                    label="Bill Savings Provider"
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={billSavingsProvider}
                    variant="outlined"
                    onChange={(ev) => {
                      setBillSavingsProvider(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getbillSavingsProviders()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Contract End Date"
                      value={contractEndDate}
                      onChange={(newValue) => {
                        setContractEndDate(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box display="flex" mb="3rem" alignItems="center">
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Deal Structure">Deal Structure</InputLabel>
                  <Select
                    id="dealStructure"
                    name="dealStructure"
                    label="Deal Structure"
                    multiple
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={dealStructure}
                    variant="outlined"
                    onChange={(ev) => {
                      setDealStructure(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getDealStructures()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <InputLabel htmlFor="Grid Services Provider">Grid Services Provider</InputLabel>
                  <Select
                    id="gridServiceProvider"
                    name="gridServiceProvider"
                    label="Grid Services Provider"
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        fontWeight: 400,
                      },
                    }}
                    size="small"
                    value={gridServicesProvider}
                    variant="outlined"
                    onChange={(ev) => {
                      setGridServicesProvider(ev.target.value);
                      setDirty(true);
                    }}
                  >
                    {getServicesProviders()}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Acquired Date"
                      value={dateAcquired}
                      onChange={(newValue) => {
                        setDateAcquired(newValue);
                        setDirty(true);
                      }}
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          size: "small",
                        },
                        openPickerIcon: {
                          fontSize: "20rem",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            </Box>
          </FormGroup>

          {isLoading ? <Loading /> : null}
          {error ? <DataComponentError /> : null}
        </Box>
        {!currentSite?.uuid && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              mt: "-1rem",
            }}
          >
            By default, all Scale users have access to new sites, and other users have no access.
            <br />
            User notifications default to On once a user has access.
          </Typography>
        )}
      </Box>
      {currentSite?.uuid && !isAssembliesLoading && (
        <Box sx={currentSite?.uuid && theme.classes.genericPanel}>
          <Typography variant="subtitle2" sx={styles.subtitle}>
            Assets
          </Typography>
          <Box display="flex" flexDirection="column" sx={styles.settingsPanel} marginTop="1rem">
            <FormGroup sx={{ width: "11.5rem", marginRight: "1.5rem" }}>
              <Box mb="-4rem">
                <SystemSizeCard
                  sitePowerCapacity={getPowerOutput(currentSite)}
                  siteEnergyCapacity={getEnergyOutput(currentSite)}
                  solarOutput={solarCapacity}
                  batteryOutput={batteryCapacity}
                  gensetOutput={gensetCapacity}
                  batteryStorage={batteryEnergyCapacity}
                />
              </Box>
              <Typography variant="h5" mb="1.5rem">
                Size Inputs
              </Typography>
              <Box display="flex" mb="1.5rem" alignItems="center">
                <TextField
                  id="solar"
                  name="solar"
                  size="small"
                  label="Solar (kW)"
                  value={solarCapacity}
                  variant="outlined"
                  onChange={(ev) => {
                    setSolarCapacity(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={(isNaN(solarCapacity) || solarCapacity < 0) && solarCapacity?.length > 0}
                  helperText={
                    (isNaN(solarCapacity) || solarCapacity < 0) && solarCapacity.length > 0
                      ? "Capacity must be a number greater than 0"
                      : ""
                  }
                />
                <TextField
                  id="battery"
                  name="battery"
                  size="small"
                  label="Battery Power (kW)"
                  value={batteryCapacity}
                  variant="outlined"
                  onChange={(ev) => {
                    setBatteryCapacity(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(batteryCapacity) || batteryCapacity < 0) && batteryCapacity?.length > 0
                  }
                  helperText={
                    (isNaN(batteryCapacity) || batteryCapacity < 0) && batteryCapacity.length > 0
                      ? "Battery power capacity must be a number greater than 0"
                      : ""
                  }
                />
                <TextField
                  id="batteryEnergy"
                  name="batteryEnergy"
                  size="small"
                  label="Battery Energy (kWh)"
                  value={batteryEnergyCapacity}
                  variant="outlined"
                  onChange={(ev) => {
                    setBatteryEnergyCapacity(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(batteryEnergyCapacity) || batteryEnergyCapacity < 0) &&
                    batteryEnergyCapacity?.length > 0
                  }
                  helperText={
                    (isNaN(batteryEnergyCapacity) || batteryEnergyCapacity < 0) &&
                    batteryEnergyCapacity.length > 0
                      ? "Battery energy must be a number greater than 0"
                      : ""
                  }
                />
                <TextField
                  id="genset"
                  name="genset"
                  size="small"
                  label="Genset (kW)"
                  value={gensetCapacity}
                  variant="outlined"
                  onChange={(ev) => {
                    setGensetCapacity(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "11.5rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  error={
                    (isNaN(gensetCapacity) || gensetCapacity < 0) && gensetCapacity?.length > 0
                  }
                  helperText={
                    (isNaN(gensetCapacity) || gensetCapacity < 0) && gensetCapacity.length > 0
                      ? "Capacity must be a number greater than 0"
                      : ""
                  }
                />
              </Box>
            </FormGroup>
          </Box>
          <Box display="flex" flexDirection="column" sx={styles.settingsPanel} marginTop="-2.5rem">
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="h5">Connected Assets</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  id="edge_id"
                  name="edge_id"
                  label="Edge Group ID"
                  variant="outlined"
                  size="small"
                  value={edgeId}
                  onChange={(ev) => {
                    setEdgeId(ev.target.value);
                    setDirty(true);
                  }}
                  sx={{
                    width: "14rem",
                    fontSize: 16,
                    fontWeight: 400,
                    marginRight: "1.5rem",
                  }}
                  InputProps={{
                    sx: { fontSize: 16, fontWeight: 400 },
                    endAdornment: (
                      <Tooltip
                        title={
                          <>
                            This input links this site to Scale Edge, to bring in the correct asset
                            data.
                            <br />
                            <br />
                            (Only relevant for Edge sites.)
                          </>
                        }
                      >
                        <IconButton size="small">
                          <LinkOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Box>
            </Box>
            {siteSpec4 && (
              <Box>
                {rows.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "1.5rem",
                      backgroundColor: "#F9F9F9",
                      mt: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: "1.5rem",
                      }}
                    >
                      <Typography variant="h6">Ready for Setup</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PlaylistPlayOutlined />}
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                          setIsPreFilledModalOpen(true);
                        }}
                      >
                        Start Assets Setup
                      </Button>
                    </Box>
                    <div style={theme.classes.tableContainer}>
                      {assembliesList ? (
                        rows.length > 0 ? (
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            autoWidth
                            disableSelectionOnClick
                            sortingOrder={["asc", "desc"]}
                            hideFooter
                            onRowClick={handleRowClick}
                            sx={{
                              "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "transparent", // Removes background
                              },
                              "&, .MuiDataGrid-columnHeader": {
                                borderTop: "none",
                              },
                              "&, [class^=MuiDataGrid]": {
                                overflowX: "hidden",
                                borderLeft: "none",
                                borderRight: "none",
                              },
                              "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: "rgba(0, 0, 0, 0.04) !important",
                              },
                            }}
                          />
                        ) : null
                      ) : (
                        <Typography
                          color="text.secondary"
                          sx={{
                            textAlign: "center",
                            marginTop: "1.5rem",
                          }}
                        >
                          Live asset data will appear here, once connected.
                        </Typography>
                      )}
                    </div>
                  </Box>
                )}
                <div
                  style={{
                    ...theme.classes.tableContainer,
                  }}
                >
                  {assetsTypeList.map((type) => {
                    const rows = filteredRows[type];
                    if (!rows || rows.length === 0) return null;
                    return (
                      <div
                        key={type}
                        style={{
                          ...theme.classes.tableContainer,
                          border: "1px solid #E1E2E4",
                          borderRadius: "4px",
                          marginTop: "2rem",
                          marginBottom: "2rem",
                          padding: "1.5rem",
                        }}
                      >
                        <Typography variant="h6" mt="1.5rem" mb="1rem">
                          {type}
                        </Typography>
                        <div style={theme.classes.tableContainer}>
                          <DataGrid
                            rows={rows}
                            columns={columnsReadyAssets}
                            autoHeight
                            autoWidth
                            disableSelectionOnClick
                            sortingOrder={["asc", "desc"]}
                            hideFooter
                            onRowClick={handleRowClick}
                            sx={{
                              "&, .MuiDataGrid-columnHeader": {
                                borderTop: "none",
                              },
                              "&, [class^=MuiDataGrid]": {
                                overflowX: "hidden",
                                borderLeft: "none",
                                borderRight: "none",
                              },
                              "& .MuiDataGrid-row.Mui-selected": {
                                backgroundColor: "rgba(0, 0, 0, 0.04) !important",
                              },
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedAsset && (
                  <AssetSetupModal
                    asset={selectedAsset}
                    site={currentSite}
                    preFill={false}
                    isOpen={isAssetModalOpen}
                    onClose={() => {
                      setIsAssetModalOpen(false);
                      setSelectedAsset(null);
                      refetchAssembliesList();
                    }}
                    index={0}
                  />
                )}
                {assetsList?.length > 0 && (
                  <AssetPreFillModal
                    assetsList={assetsList}
                    site={currentSite}
                    isOpen={isPreFilledModalOpen}
                    onClose={() => {
                      setIsPreFilledModalOpen(false);
                      refetchAssembliesList();
                    }}
                    onSave={() => {
                      refetchAssembliesList();
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box
        pt="2rem"
        pb="-1.5rem"
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        {currentSite?.uuid ? (
          <Button
            sx={{
              width: "150px",
            }}
            variant="outlined"
            onClick={handleDelete}
            color="error"
          >
            Delete Site...
          </Button>
        ) : (
          <Box flexGrow={1} />
        )}
        <Box>
          {!currentSite?.uuid && (
            <Button
              variant="outlined"
              sx={{
                marginLeft: "1em",
                width: "150px",
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            loading={mutation?.isLoading || mutation_new_site?.isLoading}
            onClick={dirty ? handleSaveAndClose : () => {}}
            variant="contained"
            sx={{ marginLeft: "1em", width: "150px" }}
            disabled={!dirty || (!allFieldsFilled() && !mutation?.isSuccess)}
            color={mutation?.isSuccess && !dirty ? "success" : "primary"}
          >
            {mutation.isSuccess && !dirty
              ? "Saved"
              : currentSite?.uuid
                ? "Save Changes"
                : "Create Site"}
          </LoadingButton>
          <DeleteSiteModal
            open={open}
            onClose={() => setOpen(false)}
            onConfirm={confirmDelete}
            siteName={currentSite?.name}
          />
        </Box>
      </Box>
    </Box>
  );
};

SiteSettings.propTypes = {
  OrganizationUuid: PropTypes.string,
  site: PropTypes.object,
  onClose: PropTypes.func,
};

export default SiteSettings;
