/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState, useCallback, useEffect, useRef } from "react";
import AssetSetupModal from "./assetModal";

interface SourceInfo {
  source: string;
  id: number;
  sub_source: string;
}

interface Asset {
  id: string;
  name: string;
  scaleos_name: string;
  scaleos_type: string;
  power_capacity: number;
  source: string;
  manufacturer: string;
  model: string;
  serial_number: string;
  mounting: string;
  trigger_alarms: boolean;
  weight: boolean;
  energy_capacity: number;
  sgip_capacity: number;
  genset_use: string;
  source_info: SourceInfo[];
}

interface Asset {
  id: string;
  name: string;
  scaleosName: string;
  scaleosType: string;
  outputCapacity: number;
  source: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  solarMounting: string;
  enableAlarms: boolean;
  showInScale: boolean;
  storageCapacity: number;
  sgipCapacity: number;
  gensetUse: string;
  sourceInfo: SourceInfo[];
}
interface Props {
  assetsList: Asset[];
  site: {
    uuid: string;
    name: string;
    edge_id: string;
  };
  isOpen: boolean;
  onClose: () => void;
  onSaveAsset: (updatedAsset: Asset) => void;
}

export default function AssetPreFillModal({ assetsList, site, isOpen, onClose }: Props) {
  const [setupAssetsList, setSetupAssetsList] = useState(assetsList);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dirty, setDirty] = useState<boolean>(false);
  const [activeAsset, setActiveAsset] = useState(assetsList[0]);
  const [savedAsset, setSavedAsset] = useState<AssetEndpoint | null>(null);
  const isFirstRender = useRef(true);
  const handlePrev = useCallback(() => {
    setDirty(false);
    setSelectedIndex((prev) => {
      const newIndex = Math.max(prev - 1, 0);
      setActiveAsset(setupAssetsList[newIndex]);
      return newIndex;
    });
  }, [setupAssetsList]);

  const handleNext = useCallback(() => {
    setDirty(false);
    setSelectedIndex((prev) => {
      const newIndex = Math.min(prev + 1, setupAssetsList.length - 1);
      setActiveAsset(setupAssetsList[newIndex]);
      return newIndex;
    });
  }, [setupAssetsList]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Skip first render
    }

    const nextIndex = Math.min(selectedIndex + 1, setupAssetsList.length - 1);
    const nextAsset = setupAssetsList[nextIndex];
    if (!nextAsset) return;

    // Fields that are updated with savedAsset values if not present in nextAsset
    const fieldMappings = {
      scaleosType: "scaleos_type",
      outputCapacity: "power_capacity",
      manufacturer: "manufacturer",
      model: "model",
      solarMounting: "mounting",
      enableAlarms: "trigger_alarms",
      showInScale: "weight",
      storageCapacity: "energy_capacity",
      sgipCapacity: "sgip_capacity",
      gensetUse: "genset_use",
    };

    const updatedAssetPreFill = {
      ...nextAsset,
      ...Object.fromEntries(
        Object.entries(fieldMappings).map(([key, savedKey]) => [
          key,
          nextAsset[key] ?? savedAsset?.[savedKey],
        ]),
      ),
    };

    setActiveAsset(updatedAssetPreFill);

    setSetupAssetsList((prevAssetsList) => {
      const updatedAssetsList = [...prevAssetsList];
      updatedAssetsList[selectedIndex] = {
        ...updatedAssetPreFill,
        id: savedAsset?.uuid,
        name: savedAsset?.name,
        scaleosName: savedAsset?.scaleos_name,
        sourceInfo: savedAsset?.source_info,
        serialNumber: savedAsset?.serial_number,
      };
      return updatedAssetsList;
    });
    setDirty(selectedIndex < setupAssetsList.length - 1);
    setSelectedIndex(nextIndex);
  }, [savedAsset]);

  return setupAssetsList?.length ? (
    <AssetSetupModal
      key={selectedIndex} // This is to re-render the modal and animate it
      asset={activeAsset}
      site={site}
      preFill={true}
      isOpen={isOpen}
      onClose={onClose}
      onPrev={selectedIndex > 0 ? handlePrev : undefined}
      onNext={selectedIndex < setupAssetsList.length - 1 ? handleNext : undefined}
      onSaveAsset={(updatedAsset) => {
        setSavedAsset((prev) => ({ ...prev, ...updatedAsset }));
      }}
      index={selectedIndex}
      preFillDirty={dirty}
    />
  ) : null;
}
