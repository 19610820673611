/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import * as React from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { getUtilityListQuery, getIsoListQuery, getGasUtilitiesListQuery } from "../../lib/Queries";
import { useQuery } from "@tanstack/react-query";

export const getValue = (value) => {
  if (!value || value === "NONE") return "-";
  return `${value} `;
};

export const getPowerValue = (value) => {
  if (value === null || value === undefined) return "-";
  return `${value} kW`;
};

export const getBatteryValues = (value1, value2) => {
  if ((value1 === null || value1 === undefined) && (value2 === null || value2 === undefined)) {
    return "-";
  }

  const formatValue = (value, unit) =>
    value === null || value === undefined ? "-" : `${value} ${unit}`;

  const formattedValue1 = formatValue(value1, "kW");
  const formattedValue2 = formatValue(value2, "kWh");

  return `${formattedValue1} / \n${formattedValue2}`;
};

export const getArrayValue = (value) => {
  if (Array.isArray(value) && value.length > 0) {
    // Join array values with commas, and add a space after each comma
    return value.join(", ");
  }
  return "-";
};

export const getDateValue = (date) => {
  if (!date) return "-";
  return dayjs(date).format("MMM D YYYY");
};

export const getPowerOutput = (site) => {
  const { solar_capacity = 0, battery_capacity = 0, genset_capacity = 0 } = site || {};
  const totalPower = solar_capacity + battery_capacity + genset_capacity;
  return totalPower > 0 ? `${totalPower} kW` : "-";
};

export const getEnergyOutput = (site) => {
  const { battery_energy_capacity = null } = site || {};
  return battery_energy_capacity != null ? `${battery_energy_capacity} kWh` : "-";
};

export const getPowerAndEnergySummary = (site) => {
  // Destructure and handle nullish values by providing default 0
  const {
    solar_capacity = null,
    battery_capacity = null,
    genset_capacity = null,
    battery_energy_capacity = null,
  } = site || {};
  // Calculate total power considering null values
  const totalPower = (solar_capacity ?? 0) + (battery_capacity ?? 0) + (genset_capacity ?? 0);

  // Format power output with "kW" or fallback to "-"
  const powerOutput =
    solar_capacity || battery_capacity || genset_capacity ? `${totalPower} kW` : "-";

  // Format energy output with "kWh" or fallback to "-"
  const energyOutput = battery_energy_capacity != null ? `${battery_energy_capacity} kWh` : "-";

  // Return formatted string or fallback to "-"
  return powerOutput === "-" && energyOutput === "-" ? "-" : `${powerOutput} / \n${energyOutput}`;
};

const SiteSpecs = ({ site }) => {
  const theme = useTheme();

  const { data: isoList, isLoading: isIsoListLoading } = useQuery({
    ...getIsoListQuery(),
  });
  const { data: gasUtilitiesList, isLoading: isGasUtilitiesListLoading } = useQuery({
    ...getGasUtilitiesListQuery(),
  });
  const { data: utilityList, isLoading: isUtilitiesListLoading } = useQuery({
    ...getUtilityListQuery(false),
  });

  const isLoading = isIsoListLoading || isGasUtilitiesListLoading || isUtilitiesListLoading;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" sx={theme.classes.genericPanel}>
      {/* Title */}
      <Box mb={4}>
        <Typography variant="subtitle2" color="secondary">
          Site Specs
        </Typography>
      </Box>
      <Box mb={6} px={8}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Connections
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Electric Utility"
                value={getValue(
                  utilityList?.utilities.find((item) => item.uuid === site?.Utility)?.name || null,
                )}
                variant="standard"
                size="small"
                multiline
                minRows={1}
                maxRows={4}
                InputProps={{
                  readOnly: true, // Makes it read-only
                  disableUnderline: true, // Removes underline
                }}
                InputLabelProps={{
                  shrink: true, // Keeps label at the top
                  focused: false, // Prevent label highlight
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Gas Utility"
                value={getValue(
                  gasUtilitiesList.find((item) => item.UUID === site?.gas_utility)?.Company || null,
                )}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="ISO / RTO"
                value={getValue(isoList?.iso.find((item) => item.uuid === site?.iso)?.name || null)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Commercial Operating Date"
                value={getDateValue(site?.commissioned_date)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="SGIP Budget Category"
                value={getValue(site?.sgip_budget_category)}
                variant="standard"
                size="small"
                multiline
                minRows={1}
                maxRows={4}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="SGIP PBI Start Date"
                value={getDateValue(site?.sgip_start_date)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mb={4} px={8}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Agreements
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Contract Type"
                value={getArrayValue(site?.contract_type)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Bill Savings Provider"
                value={getValue(site?.bill_savings_provider)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Grid Services Provider"
                value={getValue(site?.grid_services_provider)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Contract End Date"
                value={getDateValue(site?.contract_end_date)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Deal Structure"
                value={getArrayValue(site?.deal_structure)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={6} sm={3}></Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Acquired Date"
                value={getDateValue(site?.date_acquired)}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={4} px={8}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary" gutterBottom>
            System Size
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Totals"
                value={getPowerAndEnergySummary(site)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                  sx: {
                    color: "secondary.main",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
                style={{ whiteSpace: "pre-line" }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Solar"
                value={getPowerValue(site?.solar_capacity)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Battery"
                value={getBatteryValues(site?.battery_capacity, site?.battery_energy_capacity)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
                style={{ whiteSpace: "pre-line" }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Genset"
                value={getPowerValue(site?.genset_capacity)}
                multiline
                minRows={1}
                maxRows={4}
                variant="standard"
                size="small"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

SiteSpecs.propTypes = {
  site: PropTypes.object,
};

export default SiteSpecs;
