/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import { ReactNode } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: ReactNode;
  actions?: ReactNode;
  sx?: SxProps<Theme>;
  showClose: boolean;
  maxWidth?: string;
}

export default function CustomModal({
  actions,
  children,
  open,
  onClose,
  title,
  sx,
  showClose,
  maxWidth,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={sx}
      PaperProps={{
        sx: {
          p: 7,
          maxWidth: maxWidth || 850,
        },
      }}
    >
      {showClose ? (
        <Box position="absolute" top={16} right={16}>
          <IconButton
            aria-label="Close"
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}

      {title && (
        <DialogTitle sx={{ p: 0, mb: 4 }} component="h3">
          <Typography variant="h3" component="span">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
      {actions && (
        <DialogActions>
          <Box>{actions}</Box>
        </DialogActions>
      )}
    </Dialog>
  );
}
