/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { SiteMetadata, GraphFavoriteData, SiteEnergyData, ManufacturerData } from "./Types";

const basePresentationUrl = process.env.REACT_APP_SMS_NOC_PRESENTATION;

/*
NTM - Create an internal instance.  This instance has the interceptors
attached to handle things like 401 and 403.  We do this so we can create
another instance without the interceptors attached for certain cases.
*/
const authenticatedInstance = axios.create();

/** Don't logout for endpoints related to user login system */
const shoudlIgnoreLogout = (url: string): boolean => {
  return url.includes("user");
};

const registerResponseInterceptor = (authLogout: Function): void => {
  const responseErrorInterceptor = (error: AxiosError): Promise<void> => {
    // check the request error code. If it is an auth problem, redirect to the login page.
    if (
      !shoudlIgnoreLogout(error.config!.url!) &&
      (error.response?.status === 401 || error.response?.status === 403)
    ) {
      // if we can't get a response here, then try to call the
      // user info API.  Did the user accidentally hit something they shouldn't have?
      // If we can't validate the user then ... ?
      authLogout({ logoutParams: { returnTo: window.location.origin } });
    }
    return Promise.reject(error);
  };

  authenticatedInstance?.interceptors.response.use((res) => res, responseErrorInterceptor);
};

const isScaleDomain = (url: string): boolean => {
  return url.includes(basePresentationUrl!);
};

const registerRequestInterceptor = (getAccessTokenSilently: Function): void => {
  const requestAuthInterceptor = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    if (!isScaleDomain(config.url!)) {
      return config;
    }

    try {
      const credential = await getAccessTokenSilently();

      config.headers = {
        Authorization: "Bearer " + credential,
        "content-type": "application/json",
      };
    } catch (error) {
      // request for credential failed so continue without one
    }
    // return scale domain request with or without credential
    return config;
  };

  // @ts-ignore
  authenticatedInstance.interceptors.request.use(requestAuthInterceptor, (req) => req);
};

const getPortfolioPower = (): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/portfolio/power/production`)
    .then((res: AxiosResponse) => res.data);
};

const getSiteTimeSeries = (
  siteUuid: string,
  start: string,
  stop: string,
  interval: string,
): Promise<any> => {
  const params = {
    interval: interval,
    start_time: start,
    stop_time: stop,
  };
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/time-series`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getSitePowerCapacity = (siteUuid: string): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/power/capacity`)
    .then((res: AxiosResponse) => res.data);
};

const getSiteEnergyProduction = (
  siteUuid: string,
  start: string,
  stop: string,
  interval: string,
): Promise<SiteEnergyData> => {
  const params = {
    interval: interval,
    start_time: start,
    stop_time: stop,
  };
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/energy/production`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getSiteAssemblies = (siteUuid: string, interval: string): Promise<any> => {
  const params = { interval: interval };
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/assemblies`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getSiteMetaDataAssemblies = (siteUuid: string): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/assets`)
    .then((res: AxiosResponse) => res.data);
};

export const getUserProfile = (): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/user`)
    .then((res: AxiosResponse) => res.data);
};

const getAlarmDetail = (siteUuid: string, alarmUuid: string): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/alarm/${alarmUuid}`)
    .then((res: AxiosResponse) => res.data);
};

const getSiteMetadata = (siteUuid: string): Promise<SiteMetadata> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}`)
    .then((res: AxiosResponse) => res.data);
};

export const getAlarmHistory = (siteUuid: string, start: string, stop: string): Promise<any> => {
  const params = {
    start_time: start,
    stop_time: stop,
  };
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/alarms/history`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getTimeSeriesMetrics = (
  siteUuid: string,
  start: string,
  stop: string,
  interval: string,
  metricsUuids: string[],
): Promise<any> => {
  const params = {
    start_time: start,
    stop_time: stop,
    interval: interval,
    metrics: metricsUuids.toString(),
  };
  return authenticatedInstance
    .get(`${basePresentationUrl}/site/${siteUuid}/time-series/metrics`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getIsoList = (): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/iso/list`)
    .then((res: AxiosResponse) => res.data);
};

const getGasUtilitiesList = (): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/gas_utilities/list`)
    .then((res: AxiosResponse) => res.data);
};

const getManufacturersList = ( scaleosType: string | null,): Promise<any> => {
  const params = {
    scaleos_type: scaleosType,
  };
  return authenticatedInstance
    .get(`${basePresentationUrl}/manufacturer`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getUtilityList = (filter: string): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/utilities/list?filter=${filter}`)
    .then((res: AxiosResponse) => res.data);
};

const getResourceList = (entity: string, scope: string): Promise<any> => {
  const params: { [key: string]: string } = {};
  if (entity !== "all") params[scope] = entity;
  return authenticatedInstance
    .get(`${basePresentationUrl}/resources/list`, { params })
    .then((res: AxiosResponse) => res.data);
};

const getSiteList = async (): Promise<any> => {
  try {
    const response = await authenticatedInstance.get(`${basePresentationUrl}/sites/list`);
    return response.data;
  } catch (error) {
    throw new Error("Problem getting site list.");
  }
};

const getOrganizationsList = async (): Promise<any> => {
  try {
    const response = await authenticatedInstance.get(
      `${basePresentationUrl}/acct_mgmt/customer/list`,
    );
    return response.data;
  } catch (error) {
    throw new Error("Problem getting organizations list.");
  }
};

const getOrganizationSiteList = async (organizationUuid: string): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/acct_mgmt/organization/sites/list/${organizationUuid}`)
    .then((res: AxiosResponse) => res.data);
};

const getCurrentAlarms = async (siteUuid: string): Promise<any> => {
  return authenticatedInstance
    .get(`${process.env.REACT_APP_SMS_NOC_PRESENTATION}/site/${siteUuid}/alarm`)
    .then((res: AxiosResponse) => res.data);
};

const getOrganizationUsersList = async (organizationUuid: string): Promise<any> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/acct_mgmt/customer/${organizationUuid}/users`)
    .then((res: AxiosResponse) => res.data);
};

const createGraphFavorite = async (graph: GraphFavoriteData): Promise<any> => {
  return authenticatedInstance
    .post(`${basePresentationUrl}/graph`, graph)
    .then((res: AxiosResponse) => res.data);
};

const editGraphFavorite = async (graph: GraphFavoriteData): Promise<any> => {
  return authenticatedInstance
    .patch(`${basePresentationUrl}/graph`, graph)
    .then((res: AxiosResponse) => res.data);
};

const deleteGraphFavorite = async (graphUuid: string): Promise<any> => {
  return authenticatedInstance
    .delete(`${basePresentationUrl}/graph/${graphUuid}`)
    .then((res: AxiosResponse) => res.data);
};

const getGraphFavorites = async (): Promise<GraphFavoriteData[]> => {
  return authenticatedInstance
    .get(`${basePresentationUrl}/graph`)
    .then((res: AxiosResponse) => res.data);
};

const getGraphFavoritesQuery = () => {
  return {
    queryKey: ["graph-favorites-"],
    queryFn: () => getGraphFavorites(),
    refetchOnMount: "always" as const,
    cacheTime: 0,
  };
};

const getPortfolioPowerQuery = () => {
  return {
    queryKey: ["portfolio-power"],
    queryFn: () => getPortfolioPower(),
    refetchInterval: 120000,
  };
};

const getSiteTimeSeriesQuery = (
  siteUuid: string,
  start: string,
  stop: string,
  interval: string,
) => {
  return {
    queryKey: ["site-time-series-" + siteUuid],
    queryFn: () => getSiteTimeSeries(siteUuid, start, stop, interval),
    // Refetch the data every 15 seconds
    refetchInterval: 15000,
  };
};

const getSitePowerCapacityQuery = (siteUuid: string) => {
  return {
    queryKey: ["site-power-capacity-" + siteUuid],
    queryFn: () => getSitePowerCapacity(siteUuid),
    refetchInterval: 15000,
  };
};

const getSiteEnergyProductionQuery = (
  siteUuid: string,
  start: string,
  stop: string,
  interval: string,
) => {
  return {
    queryKey: ["site-energy-production-", siteUuid, start, stop, interval],
    queryFn: () => getSiteEnergyProduction(siteUuid, start, stop, interval),
    refetchInterval: 15000,
  };
};

const getSiteAssembliesQuery = (siteUuid: string) => {
  return {
    queryKey: ["site-assemblies-" + siteUuid],
    queryFn: () => getSiteAssemblies(siteUuid, "120"),
  };
};

const getSiteMetaDataAssembliesQuery = (siteUuid: string) => {
  return {
    queryKey: ["site-assemblies-metadata" + siteUuid],
    queryFn: () => getSiteMetaDataAssemblies(siteUuid),
  };
};

const getUserProfileQuery = () => {
  return {
    queryKey: ["user-profile"],
    queryFn: () => getUserProfile(),
  };
};

const getUserProfileMutation = () => {
  return {
    mutationKey: ["user-profile"],
    mutationFn: (userProfile: any) => {
      return authenticatedInstance.put(`${basePresentationUrl}/user`, userProfile);
    },
  };
};

const getSiteSettingsMutation = () => {
  return {
    mutationKey: ["site-settings"],
    mutationFn: (siteSettings: any) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/acct_mgmt/site/${siteSettings.uuid}`,
        siteSettings,
      );
    },
  };
};

const putUserSettingsMutation = () => {
  return {
    mutationKey: ["user-settings"],
    mutationFn: (userSettings: any) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/acct_mgmt/user/update`,
        userSettings,
      );
    },
  };
};

const putOwnUserSettingsMutation = () => {
  return {
    mutationKey: ["user-own-settings"],
    mutationFn: (userSettings: any) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/user`,
        userSettings,
      );
    },
  };
};

const putOrganizationSettingsMutation = () => {
  return {
    mutationKey: ["organization-settings"],
    mutationFn: (organizationSettings: any) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/acct_mgmt/organization/${organizationSettings.uuid}`,
        organizationSettings,
      );
    },
  };
};

const putAssetMetadataMutation = () => {
  return {
    mutationKey: ["asset-metadata"],
    mutationFn: ( asset: any) => {
      return authenticatedInstance.put(
        `${basePresentationUrl}/acct_mgmt/site/${asset.siteUuid}/assets/${asset.uuid}?site_name=${asset.siteName}&group_id=${asset.siteGroupId}`,
        asset,
      );
    },
  };
};

const postOrganizationSettingsMutation = () => {
  return {
    mutationKey: ["new-organization"],
    mutationFn: (organizationSettings: any) => {
      return authenticatedInstance.post(
        `${basePresentationUrl}/acct_mgmt/customer/add`,
        organizationSettings,
      );
    },
  };
};

const postSiteSettingsMutation = () => {
  return {
    mutationKey: ["new-site"],
    mutationFn: (siteSettings: any) => {
      return authenticatedInstance.post(`${basePresentationUrl}/acct_mgmt/site`, siteSettings);
    },
  };
};

const postUserSettingsMutation = () => {
  return {
    mutationKey: ["new-user"],
    mutationFn: (userSettings: any) => {
      return authenticatedInstance.post(`${basePresentationUrl}/acct_mgmt/user`, userSettings);
    },
  };
};

const postManufacturerMutation = () => {
  return {
    mutationKey: ["new-manufacturer"],
    mutationFn: (manufacturerData: ManufacturerData) => {
      return authenticatedInstance.post(`${basePresentationUrl}/acct_mgmt/manufacturer`, manufacturerData);
    },
  };
};

const deleteOrganizationMutation = () => {
  return {
    mutationKey: ["organization-delete"],
    mutationFn: (organizationUuid: string) => {
      return authenticatedInstance.delete(
        `${basePresentationUrl}/acct_mgmt/customer/${organizationUuid}`,
      );
    },
  };
};

const deleteUserMutation = () => {
  return {
    mutationKey: ["user-delete"],
    mutationFn: (userEmail: string) => {
      return authenticatedInstance.delete(`${basePresentationUrl}/acct_mgmt/user/${userEmail}`);
    },
  };
};

const getAlarmDetailQuery = (siteUuid: string, alarmUuid: string) => {
  return {
    queryKey: ["alarm-detail-" + siteUuid + "-" + alarmUuid],
    queryFn: () => getAlarmDetail(siteUuid, alarmUuid),
  };
};

const getSiteMetadataQuery = (siteUuid: string) => {
  return {
    queryKey: ["site-metadata-" + siteUuid],
    queryFn: () => getSiteMetadata(siteUuid),
  };
};

const getAlarmHistoryQuery = (siteUuid: string, start: string, stop: string) => {
  return {
    queryKey: ["alarm-history-" + siteUuid],
    queryFn: () => getAlarmHistory(siteUuid, start, stop),
    refetchInterval: 15000,
  };
};

const getTimeSeriesMetricsQuery = (
  siteUuid: string,
  start: string,
  stop: string,
  interval: string,
  metricsUuids: string[],
) => {
  const queryKey = [
    "time-series-metrics",
    siteUuid,
    start,
    stop,
    interval,
    metricsUuids.toString(),
  ];
  return {
    queryKey: queryKey,
    queryFn: () => getTimeSeriesMetrics(siteUuid, start, stop, interval, metricsUuids),
  };
};

const getIsoListQuery = () => {
  return {
    queryKey: ["iso-list"],
    queryFn: () => getIsoList(),
  };
};

const getGasUtilitiesListQuery = () => {
  return {
    queryKey: ["gas-list"],
    queryFn: () => getGasUtilitiesList(),
  };
};

const getManufacturersListQuery = ( scaleosType: string | null,) => {
  const queryKey = [
    "manufacturers-list",
    scaleosType,
  ];
  return {
    queryFn: () => getManufacturersList(scaleosType),
  };
};

const getUtilityListQuery = (filter: string) => {
  return {
    queryKey: ["utility"],
    queryFn: () => getUtilityList(filter),
  };
};

const getResourceListQuery = (entity: string, scope: string) => {
  return {
    queryKey: ["resource-" + scope + "-" + entity],
    queryFn: () => getResourceList(entity, scope),
  };
};

const getSiteListQuery = () => {
  return {
    queryKey: ["site-list"],
    queryFn: () => getSiteList(),
  };
};

const getOrganizationsListQuery = () => {
  return {
    queryKey: ["organizations-list"],
    queryFn: () => getOrganizationsList(),
  };
};

const getOrganizationSitesListQuery = (organizationUuid: string) => {
  return {
    queryKey: ["organization-sites-list-" + organizationUuid],
    queryFn: () => getOrganizationSiteList(organizationUuid),
  };
};

const getCurrentAlarmsQuery = (siteUuid: string) => {
  return {
    queryKey: ["current-alarms-" + siteUuid],
    queryFn: () => getCurrentAlarms(siteUuid),
  };
};

const getOrganizationUsersListQuery = (organizationUuid: string) => {
  return {
    queryKey: ["organization-users-" + organizationUuid],
    queryFn: () => getOrganizationUsersList(organizationUuid),
  };
};

export {
  getPortfolioPowerQuery,
  getSiteTimeSeriesQuery,
  getSitePowerCapacityQuery,
  getSitePowerCapacity,
  getSiteEnergyProductionQuery,
  getSiteAssembliesQuery,
  getSiteMetaDataAssembliesQuery,
  getUserProfileQuery,
  getUserProfileMutation,
  getSiteSettingsMutation,
  getAlarmDetailQuery,
  getSiteMetadataQuery,
  getAlarmHistoryQuery,
  getCurrentAlarmsQuery,
  getCurrentAlarms,
  getTimeSeriesMetricsQuery,
  getIsoListQuery,
  getGasUtilitiesListQuery,
  getManufacturersListQuery,
  getUtilityListQuery,
  getResourceListQuery,
  getSiteTimeSeries,
  getTimeSeriesMetrics,
  getSiteList,
  getSiteListQuery,
  getOrganizationsListQuery,
  putOrganizationSettingsMutation,
  putAssetMetadataMutation,
  postOrganizationSettingsMutation,
  deleteOrganizationMutation,
  getOrganizationSitesListQuery,
  postSiteSettingsMutation,
  getOrganizationUsersListQuery,
  postUserSettingsMutation,
  postManufacturerMutation,
  deleteUserMutation,
  putUserSettingsMutation,
  putOwnUserSettingsMutation,
  registerRequestInterceptor,
  registerResponseInterceptor,
  createGraphFavorite,
  editGraphFavorite,
  getGraphFavoritesQuery,
  deleteGraphFavorite,
};
