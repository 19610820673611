/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./style/theme";
import FallbackComponent from "./components/Common/FallbackComponent";
import ScrollToTop from "./components/Shared/ScrollToTop";
import { LicenseInfo } from "@mui/x-license";
import Auth0ProviderWithNavigate from "./components/Auth/Auth0ProviderWithNavigate";
import CustomSnackbarProvider from "./components/CustomSnackbarProvider";

import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DNS}`,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_PRO_LICENSE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
    },
  },
});

const LDProvider = withLDProvider({
  clientSideID: `${process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID}`,
  context: {
    kind: "user",
    key: "defaultUser",
    name: "defaultUser",
    email: "defaultUser@scalemicrogrids.com",
  },
})(App);

root.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <ThemeProvider theme={theme}>
            <CustomSnackbarProvider>
              <ScrollToTop />
              <LDProvider />
            </CustomSnackbarProvider>
          </ThemeProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
