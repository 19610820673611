/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Tooltip,
  InputAdornment,
  Autocomplete,
  Alert,
  FormHelperText,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { assetsTypeList, solarMountingTypes, gensetTypes } from "../../lib/siteData";
import RenameModal from "../Common/RenameModal";
import { ReactComponent as DieselGeneratorIcon } from "../../static/images/enapterdieselgenerator.svg";
import { ReactComponent as EnergyStorageIcon } from "../../static/images/enapterenergystorage.svg";
import { ReactComponent as MicrochipIcon } from "../../static/images/enaptermicrochip.svg";
import { ReactComponent as SolarPanelIcon } from "../../static/images/enaptersolarpanel.svg";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  putAssetMetadataMutation,
  getManufacturersListQuery,
  postManufacturerMutation,
} from "../../lib/Queries";
import ArrowCircleRightOutlined from "@mui/icons-material/ArrowCircleRightOutlined";
interface SourceInfo {
  source: string;
  id: number;
  sub_source: string;
}

interface Asset {
  id: string;
  name: string;
  scaleosName: string;
  scaleosType: string;
  outputCapacity: number;
  source: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  solarMounting: string;
  enableAlarms: boolean;
  showInScale: boolean;
  storageCapacity: number;
  sgipCapacity: number;
  gensetUse: string;
  sourceInfo: SourceInfo[];
}
interface Props {
  asset: Asset;
  site: {
    uuid: string;
    name: string;
    edge_id: string;
  };
  preFill: boolean;
  isOpen: boolean;
  onClose: () => void;
  onPrev?: () => void;
  onNext?: () => void;
  onSave?: () => void;
  onSaveAsset?: (updatedAsset: Asset) => void;
  index: number;
  preFillDirty: boolean;
}

export default function AssetSetupModal({
  asset,
  site,
  isOpen,
  preFill,
  onClose,
  onPrev,
  onNext,
  onSave,
  onSaveAsset,
  index,
  preFillDirty,
}: Props) {
  const theme = useTheme();
  const [scaleosName, setScaleosName] = useState<string | null>(asset.scaleosName ?? null);
  const [scaleosType, setScaleosType] = useState<string | null>(asset.scaleosType ?? null);
  const [outputCapacity, setOutputCapacity] = useState<number | null>(asset.outputCapacity ?? null);
  const [source, setSource] = useState<string | null>(asset.source ?? null);
  const [manufacturer, setManufacturer] = useState<string | null>(asset.manufacturer ?? null);
  const [model, setModel] = useState<string | null>(asset.model ?? null);
  const [serialNumber, setSerialNumber] = useState<string | null>(asset.serialNumber ?? null);
  const [solarMounting, setSolarMounting] = useState<string | null>(asset.solarMounting ?? null);
  const [enableAlarms, setEnableAlarms] = useState<boolean>(asset.enableAlarms ?? true);
  const [showInScale, setShowInScale] = useState<boolean>(asset.showInScale ?? true);
  const [storageCapacity, setStorageCapacity] = useState<number | null>(
    asset.storageCapacity ?? null,
  );
  const [sgipCapacity, setSgipCapacity] = useState<number | null>(asset.sgipCapacity ?? null);
  const [gensetUse, setGensetUse] = useState<string>(asset.gensetUse ?? null);
  const [dirty, setDirty] = useState(preFillDirty ?? false);
  const [typedManufacturer, setTypedManufacturer] = useState("");
  const [typedModel, setTypedModel] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputModelValue, setInputModelValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModelModal, setOpenModelModal] = useState(false);
  useEffect(() => {
    setScaleosName(asset.scaleosName ?? null);
    setScaleosType(asset.scaleosType ?? null);
    setOutputCapacity(asset.outputCapacity ?? null);
    setSource(asset.source ?? null);
    setManufacturer(asset.manufacturer ?? null);
    setModel(asset.model ?? null);
    setSerialNumber(asset.serialNumber ?? null);
    setSolarMounting(asset.solarMounting ?? null);
    setEnableAlarms(asset.enableAlarms ?? true);
    setShowInScale(asset.showInScale ?? true);
    setStorageCapacity(asset.storageCapacity ?? null);
    setSgipCapacity(asset.sgipCapacity ?? null);
    setGensetUse(asset.gensetUse ?? null);
    setTypedManufacturer("");
    setTypedModel("");
  }, [index]);
  const mutation = useMutation({
    ...putAssetMetadataMutation(),
    onSuccess: () => {},
  });

  const mutationNewManufacturer = useMutation({
    ...postManufacturerMutation(),
  });
  const getAssetIcon = (type: string | null) => {
    switch (type) {
      case "Solar":
        return <SolarPanelIcon />;
      case "Inverter":
        return <SolarPanelIcon />;
      case "BESS":
        return <EnergyStorageIcon />;
      case "Genset":
        return <DieselGeneratorIcon />;
      case "Controller":
        return <MicrochipIcon />;
    }
  };

  const allFieldsFilled = () => {
    if (!scaleosType) {
      return false;
    }

    if (scaleosType === "BESS") {
      return outputCapacity && storageCapacity;
    }

    if (scaleosType === "Controller") {
      return true;
    }

    return !!outputCapacity;
  };

  const saveSettings = () => {
    const updatedAsset = {
      uuid: asset.id,
      name: asset.name,
      scaleos_name: scaleosName,
      scaleos_type: scaleosType,
      power_capacity: outputCapacity,
      energy_capacity: storageCapacity,
      trigger_alarms: enableAlarms,
      trigger_data_alarms: enableAlarms,
      manufacturer: manufacturer,
      model: model,
      serial_number: serialNumber,
      mounting: solarMounting,
      weight: showInScale,
      source_info: asset.sourceInfo,
      siteName: site.name,
      siteUuid: site.uuid,
      siteGroupId: site.edge_id ?? null,
      genset_use: gensetUse,
      sgip_capacity: sgipCapacity,
    };
    mutation.mutate(updatedAsset, {
      onSuccess: () => {
        if (preFill && onSaveAsset) {
          onSaveAsset(updatedAsset);
        } else {
          onSave;
          onClose();
        }
        setDirty(false);
      },
    });
  };

  const {
    data: manufacturersList,
    isLoading: manufacturersListIsLoading,
    refetch: refetchManufacturersList,
  } = useQuery({
    ...getManufacturersListQuery(scaleosType),
  });

  const handleSaveManufacturer = () => {
    const manufacturerData = {
      name: typedManufacturer,
    };
    mutationNewManufacturer.mutate(manufacturerData, {
      onSuccess: () => {
        refetchManufacturersList();
        setOpenModal(false);
        setDirty(false);
      },
    });
  };

  const handleSaveModel = () => {
    const manufacturerModelData = {
      uuid: manufacturer,
      model: [
        {
          name: typedModel,
          scaleos_type: scaleosType,
          output_capacity: outputCapacity,
        },
      ],
    };
    mutationNewManufacturer.mutate(manufacturerModelData, {
      onSuccess: () => {
        refetchManufacturersList();
        setOpenModelModal(false);
        setDirty(false);
      },
    });
  };

  useEffect(() => {
    if (scaleosType) {
      refetchManufacturersList();
    }
  }, [scaleosType]);

  useEffect(() => {
    if (typedManufacturer && Array.isArray(manufacturersList)) {
      const matchedManufacturer = manufacturersList.find((m) => m.name === typedManufacturer);
      if (matchedManufacturer) {
        setManufacturer(matchedManufacturer.uuid);
      }
    }
  }, [manufacturersList, typedManufacturer]);

  useEffect(() => {
    if (typedModel && Array.isArray(manufacturersList)) {
      const selectedManufacturer = manufacturersList.find((m) => m.uuid === manufacturer);
      if (selectedManufacturer && Array.isArray(selectedManufacturer.model)) {
        const matchedModel = selectedManufacturer.model.find((m) => m.name === typedModel);
        if (matchedModel) {
          setModel(matchedModel.uuid);
        }
      }
    }
  }, [manufacturersList, typedModel]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 848,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 32,
            right: 32,
            pointerEvents: "none",
          }}
          aria-label="asset type"
          disableRipple
        >
          {getAssetIcon(scaleosType)}
        </IconButton>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Asset Setup
        </Typography>

        <Typography sx={{ mb: 2 }} variant="h5">
          {scaleosName || asset.name}
        </Typography>

        <Typography sx={{ mb: 3, color: "text.secondary" }} variant="subtitle2">
          {asset.name}
          {"\u00A0\u00A0"}•{"\u00A0\u00A0"}Available since {asset.lastUpdate}
        </Typography>

        <Grid container spacing={2}>
          {/* Row 1: Scale Name, Asset Type, Output Capacity, Source */}
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                label="Scale Name"
                size="small"
                value={scaleosName ?? ""}
                onChange={(e) => {
                  setScaleosName(e.target.value);
                  setDirty(true);
                }}
                helperText={
                  <Tooltip
                    title={
                      <>
                        Scale Name appears in Overview, Specs, Markets, and the customer portal.
                        <br />
                        <br />
                        If there is no Scale Name, the Asset Name is displayed.
                      </>
                    }
                    arrow
                  >
                    <span>Where does this appear?</span>
                  </Tooltip>
                }
                FormHelperTextProps={{
                  sx: { color: "primary.main", textDecoration: "underline" },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel required>Asset Type</InputLabel>
              <Select
                size="small"
                value={scaleosType}
                label="Asset Type"
                onChange={(e) => {
                  setScaleosType(e.target.value);
                  setDirty(true);
                }}
              >
                {assetsTypeList.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {["Inverter", "Controller"].includes(scaleosType) && (
                <FormHelperText>{scaleosType} data is only shown in Grapher.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {scaleosType !== "Controller" && (
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Output Capacity"
                required
                size="small"
                value={outputCapacity}
                onChange={(e) => {
                  const value = e.target.value;
                  setOutputCapacity(value ? Number(value) : null);
                  setDirty(true);
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <TextField
              fullWidth
              disabled
              label="Source"
              size="small"
              value={source}
              required
              onChange={(e) => {
                setSource(e.target.value);
                setDirty(true);
              }}
            />
          </Grid>
          <Grid item xs={12} />
          {/* Row 2: Manufacturer, Model, Serial No */}
          <Grid item xs={3}>
            <Autocomplete
              id="manufacturer"
              size="small"
              noOptionsText={
                <span
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => {
                    setTypedManufacturer(inputValue);
                    setOpenModal(true);
                  }}
                >
                  Add "{inputValue}"
                </span>
              }
              disabled={!scaleosType}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
              options={manufacturersList || []}
              loading={manufacturersListIsLoading}
              value={manufacturersList?.find((item: any) => item.uuid === manufacturer) || null}
              onChange={(event, newValue) => {
                setManufacturer(newValue ? newValue.uuid : "");
                setModel(null);
                setDirty(true);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Manufacturer" variant="outlined" />
              )}
              getOptionLabel={(option) => option.name}
              key={(option) => option.uuid}
            />
            {/* Modal for adding a new option */}
            <RenameModal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              onSave={() => handleSaveManufacturer()}
              currentName={typedManufacturer}
              title="Add a Manufacturer"
              label="Manufacturer Name"
              okButtonLabel="Create Manufacturer"
              cancelButtonLabel="Cancel"
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id="model"
              size="small"
              disabled={!manufacturer}
              noOptionsText={
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTypedModel(inputModelValue);
                    setOpenModelModal(true);
                  }}
                >
                  Add "{inputModelValue}"
                </span>
              }
              inputValue={inputModelValue}
              onInputChange={(event, newInputValue) => setInputModelValue(newInputValue)}
              options={
                manufacturersList
                  ?.find((m) => m.uuid === manufacturer) // Find the selected manufacturer
                  ?.model?.map((m) => ({
                    uuid: m.uuid,
                    name: m.name,
                  })) || [] // If no models, return an empty array
              }
              loading={manufacturersListIsLoading}
              value={
                manufacturer
                  ? manufacturersList
                      ?.flatMap((manufacturer) => manufacturer.model || [])
                      .find((m) => m.uuid === model) || null
                  : null
              }
              onChange={(event, newValue) => {
                setModel(newValue ? newValue.uuid : "");
                setDirty(true);
              }}
              renderInput={(params) => <TextField {...params} label="Model" variant="outlined" />}
              getOptionLabel={(option) => option?.name || ""}
              key={(option) => option.uuid}
            />
            <RenameModal
              isOpen={openModelModal}
              onClose={() => setOpenModelModal(false)}
              onSave={() => handleSaveModel()}
              currentName={typedModel}
              title="Add a Model"
              label="Model Name"
              okButtonLabel="Create Model"
              cancelButtonLabel="Cancel"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Serial No"
              size="small"
              value={serialNumber}
              onChange={(e) => {
                setSerialNumber(e.target.value);
                setDirty(true);
              }}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          {/* Row 3: Solar Mounting */}
          {["Solar", "Inverter"].includes(scaleosType) && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Solar Mounting</InputLabel>
                <Select
                  size="small"
                  value={solarMounting}
                  label="Solar Mounting"
                  onChange={(e) => {
                    setSolarMounting(e.target.value);
                    setDirty(true);
                  }}
                >
                  {solarMountingTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {/* Row 3: BESS fields */}
          {scaleosType === "BESS" && (
            <>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Storage Capacity"
                  size="small"
                  required
                  value={storageCapacity}
                  onChange={(e) => {
                    const value = e.target.value;
                    setStorageCapacity(value ? Number(value) : null);
                    setDirty(true);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="SGIP Capacity (CA Only)"
                  size="small"
                  value={sgipCapacity}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSgipCapacity(value ? Number(value) : null);
                    setDirty(true);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </>
          )}
          {/* Row 3: Genset Type */}
          {scaleosType === "Genset" && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Genset Use</InputLabel>
                <Select
                  size="small"
                  value={gensetUse}
                  label="Genset Use"
                  onChange={(e) => {
                    setGensetUse(e.target.value);
                    setDirty(true);
                  }}
                >
                  {gensetTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {/* Row 4: Checkboxes */}
          <Grid item xs={12} />
          {scaleosType !== "Controller" && (
            <Grid sx={{ ml: 1 }} item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableAlarms}
                    onChange={(e) => {
                      setEnableAlarms(e.target.checked);
                      setDirty(true);
                    }}
                    sx={{ transform: "scale(1.5)" }}
                  />
                }
                label={<Typography variant="body1">Enable alarms</Typography>}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showInScale}
                  onChange={(e) => {
                    setShowInScale(e.target.checked);
                    setDirty(true);
                  }}
                  sx={{ transform: "scale(1.5)" }}
                />
              }
              label={<Typography variant="body1">Show in Scale software</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            {preFill && (
              <Alert
                icon={<ArrowCircleRightOutlined fontSize="small" />}
                severity="info"
                sx={{
                  backgroundColor: "#E5F6FD",
                  color: "#014361",
                  mb: 2,
                }}
              >
                For faster setup, most asset info will pre-fill the next asset in the setup flow.
              </Alert>
            )}
          </Grid>
          {/* Row 5: Buttons */}
          <Grid item xs={12} sx={{ display: "flex" }}>
            {preFill && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  disabled={!onPrev}
                  onClick={onPrev}
                  sx={{
                    color: "black",
                    borderColor: "rgba(0, 0, 0, 0.87)",
                  }}
                  variant="outlined"
                >
                  View Previous
                </Button>
                <Button
                  disabled={!onNext}
                  onClick={onNext}
                  sx={{
                    color: "black",
                    borderColor: "rgba(0, 0, 0, 0.87)",
                  }}
                  variant="outlined"
                >
                  View Next
                </Button>
              </Box>
            )}

            <Box sx={{ display: "flex", gap: 2, ml: "auto" }}>
              <Button
                onClick={onClose}
                sx={{
                  color: "black",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                variant="outlined"
              >
                Close
              </Button>
              <LoadingButton
                loading={mutation?.isLoading}
                onClick={allFieldsFilled() && dirty ? saveSettings : () => {}}
                variant="contained"
                disabled={(!allFieldsFilled() && !mutation?.isSuccess) || !dirty}
              >
                {preFill && onNext ? "Save & Pre-fill Next" : "Save Changes"}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
