/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";
import { Box } from "@mui/material";
import Stat from "./Stat";
import PropTypes from "prop-types";

const HeroStats = ({ portfolioStats }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box
        width="100%"
        ml="1em"
        mr="1em"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        display="flex"
      >
        <Stat
          type="totalCapacity"
          title={portfolioStats.contractedGeneration + "MW"}
          subtitle="Total Contracted Generation"
        />
        <Stat
          type="availableBess"
          title={portfolioStats.contractedBessStorage + "MWh"}
          subtitle="Total Contracted Storage"
        />
      </Box>
      <Box
        width="100%"
        ml="1em"
        mr="1em"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        display="flex"
      >
        <Stat
          type="totalCapacity"
          title={parseFloat(portfolioStats.totalCapacity).toFixed(2) + "MW"}
          subtitle="Total Commissioned Generation"
        />
        <Stat
          type="availableBess"
          title={portfolioStats.bessEnergyCapacity + "MWh"}
          subtitle="Total Commissioned Storage"
        />
      </Box>
      <Box
        width="100%"
        ml="1em"
        mr="1em"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        display="flex"
      >
        <Stat
          type="currentSolarPower"
          title={(portfolioStats.solarTotalProduction || 0) + "MW"}
          subtitle="Total Solar Production (Live)"
        />
        {/*<Stat type="availableBess" title={(portfolioStats.bessTotalEnergy || 0) + "MWh"} subtitle="Stored Energy"/>*/}
        <Stat
          type="availableGenset"
          title={(portfolioStats.gensetTotalProduction || 0) + "MW"}
          subtitle="Total Genset Production (Live)"
        />
      </Box>
    </Box>
  );
};

HeroStats.propTypes = {
  portfolioStats: PropTypes.object,
};

export default HeroStats;
