/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState, useEffect } from "react";
import { Box, Popover } from "@mui/material";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import PropTypes from "prop-types";
import MapPopup from "./MapPopup";
import { getMarkerColor } from "../../lib/Util";

const markerSize = 6;

const SiteMap = ({ sites }) => {
  const [tooltipContent, setTooltipContent] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [scaleFactor, setScaleFactor] = useState(1);

  useEffect(() => {
    if (sites?.length > 0) {
      let newMarkers = [];
      sites.forEach((site) => {
        // Exclude sites with state "Pre-Commissioning"
        if (site.state === "Pre-Commissioning") {
          return;
        }

        let marker = {};
        marker.site = site;
        marker.name = site.name;
        marker.markerColor = getMarkerColor(site);

        marker.coordinates = [site.longitude, site.latitude];

        if (marker.coordinates) {
          newMarkers.push(marker);
        }
      });
      setMarkers(newMarkers);
    }
  }, [sites]);

  const geoUrl = "/static/resources/usmap.json";

  return (
    <Box
      height="500px"
      ml="1em"
      mr="1em"
      className="sms-component"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Popover
        anchorEl={tooltipContent?.event.target}
        open={Boolean(tooltipContent)}
        onClose={() => setTooltipContent(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        container={Document.body}
      >
        {tooltipContent ? <MapPopup site={tooltipContent.site} /> : null}
      </Popover>
      <ComposableMap projection="geoAlbers">
        <ZoomableGroup
          center={[0, 0]}
          zoom={1}
          maxZoom={30}
          onMoveEnd={({ zoom }) => setScaleFactor(zoom)}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography key={geo.rsmKey} geography={geo} stroke="#7b9ab3" fill="#4b7597" />
              ))
            }
          </Geographies>
          {markers.map(({ site, coordinates, markerColor }) => (
            <Marker key={site?.name} coordinates={coordinates}>
              <circle
                r={markerSize / scaleFactor}
                fill={markerColor}
                stroke="#fff"
                strokeWidth={2 / scaleFactor}
                onClick={(ev) => {
                  setTooltipContent({ event: ev, site: site });
                }}
              />
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
};

SiteMap.propTypes = {
  sites: PropTypes.array,
};

export default SiteMap;
