/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import { Box, Button, Typography } from "@mui/material";
import CustomModal from "./CustomModal";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  title?: string;
  subtitle?: string;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  isWarning?: boolean;
  currentName: string;
  label: string;
}

export default function RenameModal({
  isOpen,
  onClose,
  onSave,
  title,
  okButtonLabel,
  cancelButtonLabel,
  isWarning,
  currentName,
  label,
}: Props) {
  if (!title) title = "Are you sure?";
  if (!okButtonLabel) okButtonLabel = "OK";
  if (!cancelButtonLabel) cancelButtonLabel = "Cancel";
  if (isWarning === undefined) isWarning = false;

  const [name, setName] = useState<string>("");

  useEffect(() => {
    setName(currentName);
  }, [currentName, isOpen]);

  const actions = (
    <Box mt={1} display="flex" justifyContent="right">
      <Button
        onClick={onClose}
        variant="outlined"
        color={isWarning ? "inherit" : "primary"}
        sx={{ mr: 2 }}
      >
        {cancelButtonLabel}
      </Button>
      <Button
        onClick={() => {
          onSave(name);
        }}
        variant="contained"
        color={isWarning ? "error" : "primary"}
        sx={{ color: "white.100" }}
      >
        {okButtonLabel}
      </Button>
    </Box>
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      showClose={false}
      actions={actions}
      sx={{
        p: 3,
        "& .MuiDialogActions-root > .MuiBox-root": {
          width: "100%",
        },
      }}
    >
      <Typography variant="h3" mb={4} textAlign="left">
        {title}
      </Typography>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        value={name}
        sx={{ width: "40rem" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
        }}
      />
    </CustomModal>
  );
}
